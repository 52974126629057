/**
 * 用户相关接口
 */
import {request} from "@/utils";
import {allApis} from "@/apis/api";


/**
 * 注册接口 todo 确定哪些是必需的参数
 * export type UserInfoType = {
 *     email?: string,
 *     phone?: string,
 *     isEffective?: number,
 *     organize?: string,
 *     realName?: string,
 *     role?: userRoles,
 *     userId?: string,
 *     userName?: string,
 *     password?: string,
 *     avatarPath?: string,
 * }
 * @param registerParams
 */
export function register(registerParams) {
    return request({
        url: '/authorizations',
        method: 'POST',
        data: registerParams
    })
}


export function userDetail(){
    return request.get(allApis.user.detail);
}
/**
 * 发送验证码
 * @param phone 手机号
 * @param email 邮箱，和手机号应该是二选一
 * @param verifyType 验证类型 //todo 根据参数推断，原来的类型没有
 */
export function sendVerifyCode({phone, email, verifyType}) {
    return request({
        url: "/user/sendVerifyCode",
        method: 'POST',
        data: {
            phone,
            email,
            verifyType
        }
    })
}

/**
 * 验证码校验
 * @param phone
 * @param email
 * @param verifyCode
 * @param verifyType
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function codeVerify({phone, email, verifyCode, verifyType}) {
    return request({
        url: "/user/sendVerifyCode",
        method: 'POST',
        data: {
            phone,
            email,
            verifyType
        }
    })
}

/**
 * 登录
 * @param phone
 * @param email
 * @param password
 * @param loginBy
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function userLogin({username,  password, type}) {
    return request.post(allApis.user.emailLogin, {
        username, password, type
    })
}

/**
 * 登出操作
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function userLogout(){
    return request.get(allApis.user.emailLogout);
}

export function updateUserInfo(data) {
  return request.post(allApis.user.updateUserInfo, data)
}

export function setEmail({email}) {
  return request.get(allApis.user.setEmail, { params: {email}})
}

export function setPassword({password}) {
  return request.get(allApis.user.setPassword, { params: {password}})
}
/**
 * 注销操作
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function cancelAaccount() {
  return request.get(allApis.user.cancelAaccount)
}
