/**
 * @description
 * @author cjl
 * @data 2024/10/16 17:10
 */
// const WEB_OFFICE_APP_ID = "SX20241012SCZJKJ"; //测试
const WEB_OFFICE_APP_ID = "AK20241017AJXFQZ"; //线上
const USER_DETAIL = "userDetail";
const LANGUAGE = "language"

export {
  WEB_OFFICE_APP_ID,
  USER_DETAIL,
  LANGUAGE
}
