import React, {useMemo} from 'react';
import ReactDOM from 'react-dom/client';
import {Provider, useSelector} from 'react-redux';
import './index.scss'
import 'normalize.css'

import store from './store/index.js'
import {App, ConfigProvider} from "antd";

import MyApp from './App.jsx'
import {BrowserRouter} from "react-router-dom";
import { LANGUAGE } from "@/constants";

/**
 * 国际化支持
 */
import {locales} from "@/locales"
import {IntlProvider} from "react-intl";
//data-picker组件语言问题，需要单独引入包
import 'dayjs/locale/zh-cn';


/**
 * antd的默认样式
 * */
const defaultData = {
    // token: {
    // colorBgBase: '#ec1111'
    // }
    token: {
        // colorBgBase: '#ec1111',
        // colorBgBody: '#1d437e', // 设置全局背景色
        colorPrimary: '#2e3246',
        colorLink: '#8517e6',
        colorInfo: '#FFB800',

        // colorBgElevated: '#fbfbfb',  // 浮层背景色
        colorBgContainer: 'rgba(0,0,0,0)',
        colorBorder: '#2e324633',
        colorSplit: '#2e324633', // 分割线颜色
        borderRadius: 8,
        fontSize: 16,
        fontSizeLG: 16,
        controlHeight: 36, // 控件高度
        colorSuccess: '#26C3F5',
        borderRadiusSM: 8,
        controlHeightLG: 42,
        // sizePopupArrow 组件箭头的尺寸
        // lineWidth  用于控制组件边框、分割线等的宽度
        // motion   配置动画效果

        // 梯度样式
        // sizeStep步长  sizeUnit单位

    },
    components: {
        // https://ant-design.antgroup.com/components/config-provider-cn#%E7%BB%84%E4%BB%B6%E9%85%8D%E7%BD%AE
        Menu: {
            itemBg: '#eeeeee',
            // itemColor: '#00000080',
            // itemSelectedBg: '#595c70',
            itemSelectedBg: '#2e3246',
            // itemSelectedColor: '#b04feb',
            itemSelectedColor: '#B04FEBFF'
        },
        Button: {
            defaultBg: 'transparent',
            defaultBorderColor: '#2e3246',
            defaultColor: '#2e3246',
            defaultGhostBorderColor: '#8517e6',
            defaultGhostColor: '#333',
            defaultShadow: 'none',
            primaryColor: '#ddf76f',
            primaryShadow: 'none',
            ghostBg: '#0000000D',
            // textHoverBg: '#8517e6',
        },
        Pagination: {
            itemInputBg: '#8517e6',
            itemSize: 36,
        },
        Tag: {
            defaultBg: '#0000000D',
            defaultColor: '#00000099',
            defaultBorderColor: '#00000080',
            defaultShadow: 'none',
        },
        Modal: {
            titleFontSize: 28,
        },
        Table: {
            headerBg: '#de8eef1a',
        }
    }
}


/**
 * 全局通知的配置
 * @type {{maxCount: number}}
 */
const notificationConfig = {
    maxCount: 1,
    // placement: 'top' as "top" | "topLeft" | "topRight" | "bottom" | "bottomLeft" | "bottomRight",
    // top: 300,
    // 不生效
    // closeIcon: null as React.ReactNode | null,
    // duration: 1,
    // className: 'flex-column',
}

function RootApp() {


    // const language = navigator.language || navigator.userLanguage;;
    //
    // console.log(language);
    /**
     * 因为要使用store中的language，所以Provider放在外面
     * @type {string|*}
     */
    const locale = useSelector(state => state.setting.locale);

    /**
     * state封装一下，缓存结果
     */
    const localeValue = useMemo(() => {
        return locales[locale];
    }, [locale]);

    return (
        <IntlProvider locale={locale} messages={localeValue}>
            <ConfigProvider theme={defaultData} locale={localeValue}>
                <BrowserRouter>
                    <App notification={notificationConfig}>
                        <MyApp locale={locale}/>
                    </App>
                </BrowserRouter>
            </ConfigProvider>
        </IntlProvider>
    )
}

//todo 初始化的时候获取浏览器默认语言
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <RootApp/>
    </Provider>
)


