/**
 * 获取浏览器的语言
 * @type {string}
 */
export const navigateLanguage = navigator.language || navigator.userLanguage;

/**
 * key value对应
 * @type {Map<string, string>}
 */
export const languageAreaMap = new Map([
  [
    "en-US", "en",
  ], [
    "en", "en"
  ],[
    "zh-CN", "zh"
  ],
  [
    "zh", "zh"
  ],  [
    "ja", "ja",
  ], [
    "ko", "ko"
  ], [
    "ko-KR", "ko"
  ], [
    "fr", "fr"
  ], [
    "vi", "vi"
  ], [
    "id", "id"
  ]
]);
