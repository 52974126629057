export const allApis = {
  user: {
    login: "/sys-user/login",
    emailLogin: "/ucenter-login/passport/login",
    logout: "/sys-user/logout",
    emailLogout: "/ucenter-login/logout",
    register: "/sys-user/register",
    detail: "/sys-user/detail",
    updateUserInfo: "/ucenter-user-info/updateUserInfo",
    updateMobile: "/ucenter-user-info/updateMobile",
    setPassword: "/ucenter-login/setPassword",
    setEmail: "/ucenter-user-info/setEmail",
    cancelAaccount: "/ucenter-user-info/cancelAaccount",
  },
  file: {
    simpleUpload: "/aora-file/simpleUpload",
    uploadLocal: "/aora-file/uploadLocal",
    partUpload: "/aora-file/partUpload",
    combineFile: "/aora-file/combineFile",
    analysisPPT: "/aora-file/analysis",
    analysisPPTNew: "/ucenter-annex-info/analysis",
    uploadFiles: "/ucenter-annex-info/upload",
    getFilesById: "ucenter-annex-info/getFileInfoById",
  },
  org: {
    getOrgById: "/aora-organize/getOrgInfoByI",
  },
  video: {
    getVideoList: "/ucenter-auto-generate/getAutoListPage",
    remove: "/ucenter-auto-generate",
    saveAutoGenerate: "/ucenter-auto-generate/saveAutoGenerate",
  },
  digital: {
    ppt: {
      list: `/ucenter-component-ppt/getPptLstPage`,
      add: `/ucenter-component-ppt/generatePPT`,
      remove: `/ucenter-component-ppt`,
      update: `/ucenter-component-ppt/update`,
      getGenerateProgress: `/ucenter-component-ppt/getGenerateProgress`,
      themeList: `/ucenter-component-ppt/getThemeList`,
      generateAnOutline: `/ucenter-component-ppt/getPPTOutline`,
    },
  },
};
