import zhCN from "antd/locale/zh_CN";

/**
 * 中文
 */
const zhLanguage = {
  ...zhCN,
  "service.language.chinese": "中文",
  "service.language.english": "英语",
  "service.language.french": "法语",
  "service.language.japanese": "日语",
  "service.language.korean": "韩语",
  "service.language.vietnamese": "越南语",
  "service.language.indonesian": "印尼语",
  "service.language.russian": "俄语",
  "service.language.german": "德语",
  "service.language.italian": "意大利语",
  "service.language.portuguese": "葡萄牙语",
  "service.language.thai": "泰语",
  "service.language.spanish": "西班牙语",

  "service.my.order": "我的订单",
  "service.my.account": "我的账号",
  "service.my.cost": "我的消耗",
  "service.logout": "退出登录",

  "service.menu.home": "首页",
  "service.menu.component": "组件",
  "service.menu.workBench": "工作台",
  "service.menu.manualRecord": "录制生成",
  "service.menu.AIRecord": "自动生成",
  "service.menu.organManage": "组织管理",
  "service.menu.component.PPT": "PPT",
  "service.menu.Translation": "翻译生成",
  "service.menu.component.outline": "PPT大纲",


  "service.home.upload": "上传内容",
  "service.home.add": "添加",
  "service.home.avatar": "头像",
  "service.home.uploadPhoto": "上传图片",
  "service.home.createAvatar": "创建头像",

  "service.nodata": "暂无数据",
  "service.unknown": "未知",
  "service.unName": "未命名",


  "service.auto.all": "全部",
  "service.auto.generated": "已生成",
  "service.auto.editing": "编辑中",

  "service.auto.list.owner": "所有者",
  "service.auto.list.updateTime": "更新时间",

  "service.auto.list.status.all": "全部",
  "service.auto.list.status.generated": "已生成",
  "service.auto.list.status.editing": "编辑中",


  "service.insert.ppt": "导入PPT",
  "service.insert.title": "说明",
  "service.insert.line1": "1、关于导入时间：导入10页，大概要30min。选择文件后会提示具体的导入时间",
  "service.insert.line2": "2、导入后幻灯片不支持编辑，仅支撑添加数字人和选择数字人声",
  "service.insert.line3": "3、导入PPT后,幻灯片和标注都会被提取",
  "service.insert.line4": "4、PPT字体建议使用微软雅黑或者宋体",

  "service.record.openCamera": "打开摄像头",
  "service.record.closeCamera": "关闭摄像头",
  "service.record.openMicro": "开启麦克风",
  "service.record.closeMicro": "关闭麦克风",
  "service.record.enterFullScreen": "进入全屏",
  "service.record.exitFullscreen": "退出全屏",
  "service.record.startRecord": "开启录制",
  "service.record.endRecord": "结束录制",
  "service.record.rejectedVideo": "您拒绝了录制授权，我们将不会录制视频",
  "service.record.rejectedMicro": "您拒绝了麦克风权限，请检查设备",
  "service.record.noMicro": "没找到麦克风设备",
  "service.record.microDisabled": "您的麦克风不可用，请检查设备",
  "service.record.wait20": "请等待二十秒之后再结束",
  "service.record.checkMicro": "设备检测失败，请检查麦克风",
  "service.record.checkCarma": "设备检测失败，请检查摄像头",
  "service.record.rejectedCamera": "您拒绝了摄像头权限，请检查设备",
  "service.record.noCamera": "没找到摄像头设备",
  "service.record.cameraDisabled": "您的摄像头不可用，请检查设备",


  "service.record.list.searchTips": "请输入作品名称",
  "service.record.list.owner": "所有者",
  "service.record.list.updateTime": "更新时间",
  "service.record.list.uploadTime": "上传时间",
  "service.record.list.opr": "操作",
  "service.record.list.title": "标题",
  "service.record.list.gender": "性别",


  "service.avatar.createAvatar": "创建/编辑数字人",
  "service.avatar.gender": "数字人性别",
  "service.avatar.female": "女性",
  "service.avatar.male": "男性",
  "service.avatar.uploadType": "格式为 .jpg .png",
  "service.avatar.upload.rule-1": "上传面部完整图片，照片越标准生成效果越好",
  "service.avatar.upload.rule-2": "需上传本人正脸，五官清晰照片，涵盖肩膀区域，建议大小不超过10MB",
  "service.avatar.upload.rule-3": "图片背景建议使用绿幕",


  "service.PPT.create": "创建/编辑PPT",
  "service.PPT.description": "描述",
  "service.PPT.inputDescription": "请输入描述",
  "service.PPT.rebuilding": "正在重新生成中...",
  "service.PPT.generateAnOutline": "生成大纲中...",
  "service.PPT.rebuild": "重新生成",
  "service.PPT.next": "下一步",
  "service.PPT.back": "返回",
  "service.PPT.outlineDescription": "您可以双击对大纲进行编辑，或者保持原样继续",
  "service.PPT.chapter": "章节",
  "service.PPT.language": "语言",
  "service.PPT.pleaseSelectALanguage": "请选择语言",
  "service.PPT.size50": "PPT大小不能超过50M",
  "service.PPT.add": "新增",
  "service.PPT.del": "删除",
  "service.PPT.demote": "降级",
  "service.PPT.selTheme": "选择主题",


  "service.record.list.digitalVoice": "创建/编辑数字人声",
  "service.record.list.name": "名称",
  "service.record.list.introduction": "简介",
  "service.record.list.voiceGender": "声音性别",
  "service.record.list.upload": "上传",
  "service.record.list.clickUpload": "点击上传",
  "service.record.list.agree": "我已阅读并同意",
  "service.record.list.agreement": "数字人声创建与使用协议",
  "service.record.list.uploadType": "格式为 .mp3 .wav",
  "service.record.list.inputName": "请输入名称",
  "service.record.list.inputIntroduction": "请输入简介",
  "service.record.list.selectGender": "请选择性别",
  "service.record.list.uploadFile": "请上传文件",
  "service.record.list.pleaseAgree": "请同意协议",
  "service.record.list.generate": "生成",
  "service.record.list.generateFailed": "生成失败",
  "service.record.list.gender.female": "女",
  "service.record.list.gender.male": "男",
  "service.record.list.selectVocal": "选择数字声音",
  "service.record.list.selectModel": "请选择PPT、数字人声和数字人",
  "service.record.list.onPublish": "发布中...",
  "service.record.list.publish": "发布",
  "service.record.list.requireVideoName": "视频名是必选字段",
  "service.record.list.videoName": "视频名",
  "service.record.list.insert": "插入",
  "service.record.list.ppt.uploadFailed": "PPT上传失败",
  "service.record.list.parsing": "解析中...",
  "service.record.list.ppt.parseSuccess": "PPT解析成功",
  "service.record.list.ppt.parseFailed": "PPT解析失败",
  "service.record.list.selectAva": "选择头像",
  "service.record.cameraTip": "您未开启摄像头或麦克风，是否继续？",
  "service.record.videoName": "视频名称",


  "service.cost.amount": "消耗金额",
  "service.cost.type": "消耗类型",
  "service.cost.pieces": "消耗件数",
  "service.cost.time": "消耗时间",
  "service.cost.videoGenerate": "视频生成",
  "service.cost.DH": "数字人制作",
  "service.cost.DV": "数字声音制作",
  "service.cost.searchTips": "请选择消耗来源",
  "service.cost.video": "视频",
  "service.cost.vocal": "声音",


  "service.order.selRechargeSource": "请选择充值来源",
  "service.order.selRechargeType": "请选择充值类型",
  "service.order.selPaymentState": "请选择支付状态",
  "service.order.inputID": "请输入订单编号",
  "service.order.recharge.wechat": "微信",
  "service.order.recharge.Alipay": "支付宝",
  "service.order.recharge.CloudQuickPass": "云闪付",
  "service.order.payment.success": "已支付",
  "service.order.payment.failed": "未支付",
  "service.order.invoice": "开发票",
  "service.order.id": "订单编号",
  "service.order.rechargeSource": "充值来源",
  "service.order.rechargeType": "充值类型",
  "service.order.paymentState": "支付状态",
  "service.order.num": "订单数目",
  "service.order.orderTime": "下单时间",
  "service.order.payTime": "付款时间",
  "service.order.video": "视频",
  "service.order.digitalVoice": "数字人声",


  "service.translation.selectLan": "请选择语言",
  "service.translation.selectVoice": "请选择声音",
  "service.translation.video": "视频翻译",
  "service.translation.originalLan": "原始语言",
  "service.translation.selOriginalLan": "请选择原始语言",
  "service.translation.translLan": "翻译语言",
  "service.translation.selTranslLan": "请选择翻译语言",
  "service.translation.uploadVideo": "上传视频",
  "service.translation.uploadVideoVail": "请上传视频",
  "service.translation.uploadSuccess": "上传成功",
  "service.translation.uploadFailed": "上传失败",
  "service.translation.generating": "翻译生成中",
  "service.translation.wait": "请等待...",


  "service.login.login": "登录",
  "service.login.logout": "退出",
  "service.login.register": "注册",
  "service.login.registerTips": "注册账号",


  "service.component.digitalVoice": "数字人声",
  "service.component.digitalFigures": "数字人",
  "service.component.ppt": "生成PPT",
  "service.component.onGenerate": "生成中",
  "service.component.timeEstimate": "时间预算",
  "service.component.timeEstimateDetail": "预计耗时xxx分钟，花费xxx元。",
  "service.component.paySuccess": "付款成功",

  "service.common.publishSuccess": "发布成功",
  "service.common.deleteSuccess": "删除成功",
  "service.common.deleteFail": "删除失败",
  "service.common.saveSuccess": "保存成功",
  "service.common.saveFail": "保存失败",
  "service.common.success": "成功",
  "service.common.Edit": "编辑",
  "service.common.confirm": "确认",
  "service.common.cancel": "取消",
  "service.common.setting": "设置",
  "service.common.None": "暂无",
  "service.common.tip": "提示",
  "service.common.title": "标题",
  "service.common.enterTitle": "请输入标题",
  "service.common.theBuildSucceeded": "生成成功",
  "service.common.downloadFailed": "下载失败",


  "service.notice.errorRequest": "请求错误: ",
  "service.notice.timeout": "请求超时，请检查网络或稍后再试",
  "service.notice.request202": "已接受请求，并进入后台排队（异步任务），但未处理完成",
  "service.notice.request203": "请求成功，非授权信息，返回的meta信息不在原始的服务器，而是一个副本",
  "service.notice.request204": "服务器成功处理，但未返回内容",
  "service.notice.request205": "服务器成功处理，客户端（浏览器）应重置页面视图",
  "service.notice.request206": "服务器成功处理了部分GET请求",
  "service.notice.request400": "发出的请求有误（客户端请求语法错误），服务器没有进行新建或修改数据的操作",
  "service.notice.request401": "用户没有权限（令牌、用户名、密码错误）或登录过期",
  "service.notice.request403": "用户得到授权，但访问已被禁止",
  "service.notice.request404": "请求的资源无法找到（资源不存在或URL错误），服务器没有进行操作",
  "service.notice.request405": "客户端请求中的方法被服务器禁止或请求方法错误",
  "service.notice.request406": "客户端请求格式错误或服务端返回内容客户端无法解析",
  "service.notice.request410": "请求的资源被永久删除，且不会再得到",
  "service.notice.request412": "客户端请求信息的先决条件错误",
  "service.notice.request413": "由于请求的实体过大，服务器无法处理，因此拒绝请求",
  "service.notice.request414": "请求的URL过长（URL通常为网址），服务器无法处理",
  "service.notice.request415": "服务器无法处理请求附带的媒体格式",
  "service.notice.request416": "客户端请求的范围无效",
  "service.notice.request422": "当创建一个对象时，发生一个验证错误",
  "service.notice.request500": "服务器发生错误，请检查服务器",
  "service.notice.request501": "服务器不支持请求的功能，无法完成请求",
  "service.notice.request502": "网关错误，从远程服务器接收到了一个无效的响应",
  "service.notice.request503": "服务不可用，服务器暂时过载或维护",
  "service.notice.request504": "网关超时，充当网关或代理的服务器，未及时从远端服务器获取请求",
  "service.notice.request505": "服务器不支持请求的HTTP协议的版本，无法完成处理",
  "service.notice.request604": "登录超时，请重新登录",
  "service.notice.request603": "登录超时，请重新登录",


  "service.username": "用户名",
  "service.email": "邮箱",
  "service.phone": "手机号",
  "service.organization": "组织",
  "service.OK": "确定",


  "tips.login.notSupport": "暂不支持",
  "tips.login.success": "登录成功",
  "tips.login.fail": "登录失败",
  "tips.login.acc": "账号",
  "tips.login.pass": "密码",
  "tips.login.agreeTips": "是否同意用户隐私协议",
  "tips.login.account": "请输入账户",
  "tips.login.password": "请输入密码",
  "tips.login.loginWithVerify": "使用验证码登录",
  "tips.login.check": "查看",
  "tips.privacy.title": "用户隐私协议",
  "tips.update.success": "修改成功",
  "tips.update.error": "修改失败",
  "tips.logOff.success": "您已注销",
  "tips.logOff.error": "注销失败",
  "tips.logOff.warning": "您确定要注销吗?",
  "tips.email.format": "请输入正确的邮箱",
  "tips.file.size5": "文件大小不能超过5M",
  "tips.logOff.tips": "注销后不可恢复，请谨慎操作",
  "tips.psw.length": "密码长度必须在6-20位之间",
  "tips.psw.chars": "密码必须包含大小写字母、数字、特殊字符中的两种",
  "tips.psw.rule": "请输入新密码，密码长度6-20位，必须包含大小写字母、数字、特殊字符中的两种",
  "tips.download.warning": "正在下载，请勿重复点击！",
  "tips.login.theAccountNumberOrPasswordIsIncorrect": "账号或密码错误！",
  "tips.login.getAVerificationCode": "获取验证码",
  "tips.login.RetryinSeconds": "秒后重试",

  "btn.text.release": "发布",
  "btn.text.modify": "修改",

  "service.user.center": "用户中心",
  "service.user.updateAvatar": "修改头像",
  "service.user.avatarFormat": "图片需大于500*500像素，支持JPG、JPEG、PNG和SVG格式，大小不超过5M",
  "service.user.nickname": "昵称",
  "service.user.cancelAccount": "注销账号",
  "service.user.cancelAccountWarning": "注销后不可恢复，请谨慎操作",
  "service.user.logOff": "注销",
  "service.user.changePsw": "修改密码",
}

export default zhLanguage;
