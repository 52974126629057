import {Dropdown, Layout, Menu, notification} from 'antd'
import {MenuFoldOutlined, MenuUnfoldOutlined,} from '@ant-design/icons'
import './index.scss'
import {useLocation, useNavigate} from 'react-router-dom'
import {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {fetchUserInfo} from '@/store/modules/user';
import {changeLanguage} from "@/store/modules/setting";
import nprogress from "nprogress";

import logo from "@/assets/images/logo.png"
import logo_menu from "@/assets/images/logo_menu.png"
import routes from "@/router";
import avatar from "@/assets/avatar.png"
import {LanguageSvg} from "@/assets/icons/language";
import {useIntl} from 'react-intl';
import {removeInfo} from "@/utils/token";
import {userLogout} from "@/apis/user";

const {Header, Sider, Content} = Layout

/**
 * 布局组件，当前只有一种布局，后续还可以动态修改
 * @returns {JSX.Element}
 * @constructor
 */
const GeekLayout = ({children}) => {

  const dispatch = useDispatch();

  const {formatMessage} = useIntl();

  const {headerHeight: height} = useSelector(state => state.setting);
  const userDetail = useSelector((state) => {
    return state.user.userDetail;
  });
  /**
   * 布局头部高度
   * @type {string}
   */
  const headerHeight = useMemo(() => {
    return height;
  }, [height])


  //路由加载进度条
  nprogress.start();

  setTimeout(() => {
    nprogress.done();
  }, 200);


  const navigate = useNavigate();
  const location = useLocation();

  //当前路径
  const [current, setCurrent] = useState(location.pathname);


  //左下角折叠，以及折叠图标
  const [collapsed, setCollapsed] = useState(false);

  //标题
  const [title, setTitle] = useState(true);

  //面包屑
  const tabItem = [];


  useEffect(() => {
    // 判断是否是登录页进入首页
    // todo 后期根据接口修改
    // const ZhuXiaoJia = localStorage.getItem("ZhuXiaoJia");
    //
    // if (ZhuXiaoJia) {
    //     const userInfo = JSON.parse(localStorage.getItem(window.envConfig['ROOT_UER_INFO']) || '')?.userInfo
    //     notification.success({
    //         message: userInfo.realName,
    //         description: "欢迎登录",
    //     });
    // }
  }, []);

  /**
   * 菜单项被点击的回调
   * 触发导航并设置被激活的菜单项
   * 因为有些subMenu没有在菜单展示，所以需要手动处理重定向
   * @param route
   */
  const onMenuClick = (route) => {

    setCurrent && setCurrent(route.key)
    // setCurrent(route);
    const path = route.key;
    const hasRedirect = routes.find((item) => {
      return item.path === path && item.redirect;
    });

    // navigate(path);

    if (hasRedirect) {
      navigate(hasRedirect.redirect);
    } else {
      navigate(path);
    }
  }


  /**
   * todo 这个接口暂时没有提供
   */
  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch])


  const setLocale = (lan) => {
    dispatch(changeLanguage(lan))
  }

  //被格式化的路由
  const formatedMenu = [];

  //临时保存子路由
  let parentChildren = [];

  /**
   * 处理路由表
   * @param routeItem
   * @param parentArray
   */
  function formatMenuItem(routeItem, parentArray) {

    /**
     * 首页
     * index.js:104 组件
     * index.js:104 声音
     * index.js:104 视频
     * index.js:104 工作台
     * index.js:104 视频列表
     * index.js:104 生成视频
     */
    if (routeItem.meta.showInMenu) {
      if (!routeItem.meta.disabled) {
        if (location.pathname.startsWith(routeItem.path)) {
          document.title = routeItem.meta.label === "首页" ? window.envConfig.ROOT_APP_NAME : formatMessage({id: routeItem.meta.label}) + "-" + window.envConfig.ROOT_APP_NAME;
          tabItem.push(formatMessage({id: routeItem.meta.label}))
        }

        const menuItem = {
          key: routeItem.path,
          label: routeItem.meta.label,
          icon: routeItem.meta.icon ?? null,
        }
        if (routeItem.children) {
          routeItem.children.forEach(subItem => {
            formatMenuItem(subItem, parentChildren);
          });
          menuItem.children = parentChildren;
          parentChildren = [];

          if (menuItem.children.length === 0) {
            delete menuItem.children;
          }
        }

        if (parentArray) {
          parentArray.push(menuItem);
        } else {
          formatedMenu.push(menuItem);
        }
      }
    }
  }

  /**
   * 根据路由表的meta信息组合出antd的menu需要的格式
   */
  routes.forEach(item => {
    formatMenuItem(item, formatedMenu);
  });


  const menu = [
    {
      key: "/home",
      label: formatMessage({id: "service.menu.home"}),
      name: "1111"
    },
    {
      key: "/component",
      label: formatMessage({id: "service.menu.component"}),
    },
    // {
    //     key: "/organization",
    //     label: formatMessage({id: "service.menu.organManage"})
    // },
    {
      key: "/dashboard",
      label: formatMessage({id: "service.menu.workBench"}),
      children: [
        {
          key: "/dashboard/video",
          label: formatMessage({id: "service.menu.manualRecord"}),
        },
        {
          key: "/dashboard/aiVideo",
          label: formatMessage({id: "service.menu.AIRecord"}),
        },
        {
          key: "/dashboard/translation",
          label: formatMessage({id: "service.menu.Translation"}),
        }
      ]
    },

  ]


  const handleSubMenuClick = (route) => {
    navigate(route);
  }
  /**
   * 退出登录的回调
   */
  const logOut = () => {

    userLogout().then(res => {
      removeInfo();
      navigate("/login")
    }).catch(err => {
      removeInfo();
      navigate("/login")
    })
  }


  const dropMenu = [
    {
      key: '/user/order',
      // 我的订单
      label: (
        <div style={{width: 100, textAlign: "center"}} onClick={() => handleSubMenuClick("/user/order")}>
          {formatMessage({id: "service.my.order"})}
        </div>
      ),
    }, {
      key: '/user/cost',
      // 我的消耗
      label: (
        <div style={{width: 100, textAlign: "center"}} onClick={() => handleSubMenuClick("/user/cost")}>
          {formatMessage({id: "service.my.cost"})}
        </div>
      ),
    },
    {
      key: '/user/account',
      // 我的账号
      label: (
        <div style={{width: 100, textAlign: "center"}} onClick={() => handleSubMenuClick("/user/account")}>
          {formatMessage({id: "service.my.account"})}
        </div>
      ),
    }, {
      // 退出登录
      key: "logout",
      label: (
        <div style={{width: 100, textAlign: "center"}} onClick={logOut}>
          {formatMessage({id: "service.logout"})}
        </div>
      )
    }
  ]

  const languageMenu = [
    {
      key: "Chinese",
      label: (
        <div onClick={() => setLocale('zh')} style={{width: 80, textAlign: "center"}}>
          {formatMessage({id: "service.language.chinese"})}
        </div>
      )
    },
    {
      key: "English",
      label: (
        <div onClick={() => setLocale('en')} style={{width: 80, textAlign: "center"}}>
          {formatMessage({id: "service.language.english"})}
        </div>
      )
    },
    {
      key: "French",
      label: (
        <div onClick={() => setLocale('fr')} style={{width: 80, textAlign: "center"}}>
          {formatMessage({id: "service.language.french"})}
        </div>
      )
    },
    {
      key: "Japanese",
      label: (
        <div onClick={() => setLocale('ja')} style={{width: 80, textAlign: "center"}}>
          {formatMessage({id: "service.language.japanese"})}
        </div>
      )
    },

    {
      key: "Korean",
      label: (
        <div onClick={() => setLocale('ko')} style={{width: 80, textAlign: "center"}}>
          {formatMessage({id: "service.language.korean"})}
        </div>
      )
    },
    {
      key: "Vietnamese",
      label: (
        <div onClick={() => setLocale('vi')} style={{width: 80, textAlign: "center"}}>
          {formatMessage({id: "service.language.vietnamese"})}
        </div>
      )
    },
    {
      key: "Indonesian",
      label: (
        <div onClick={() => setLocale('id')} style={{width: 80, textAlign: "center"}}>
          {formatMessage({id: "service.language.indonesian"})}
        </div>
      )
    },
    {
      key: "Spanish",
      label: (
        <div
          onClick={() => setLocale("es")}
          style={{ width: 80, textAlign: "center" }}
        >
          {formatMessage({ id: "service.language.spanish" })}
        </div>
      ),
    },
  ];


  return (
    <Layout className="comment-layout">
      <Sider width={250} trigger={null} collapsible collapsed={collapsed}>
        <div className="logo flex-c">
          {title ? <img src={logo_menu} alt=""/> : <img src={logo} alt=""/>}
        </div>
        <div className='menus'>
          <Menu
            onClick={onMenuClick}
            // theme="light"
            style={{
              width: '100%',
              overflowY: "hidden"
            }}
            selectedKeys={[current]}
            defaultSelectedKeys={['/home']}
            // defaultOpenKeys={['sub1']}
            mode="inline"
            items={menu}
          />
        </div>
      </Sider>

      <Layout className="site-layout">
        <Header className="flex layout-header" style={{height: headerHeight}}>
          {collapsed ?
            <MenuUnfoldOutlined className={'trigger'} onClick={() => {
              setCollapsed(!collapsed);
              setTitle(!title)
            }}/>
            : <MenuFoldOutlined className={'trigger'} onClick={() => {
              setCollapsed(!collapsed);
              setTitle(!title)
            }}/>
          }
          <div className='flex flex1'>
            <div className='breadcrumb'>
              {/*<Logo/>*/}
              {tabItem.map((item, index) => index < tabItem.length - 1 ? item + ' / ' : item).join('')}
            </div>

            <div className="flex">
              <Dropdown menu={{items: languageMenu}} placement="bottom">
                <div style={{
                  width: 40,
                  height: 40,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <LanguageSvg/>
                </div>
              </Dropdown>

              <Dropdown
                menu={{
                  items: dropMenu,
                }}
                placement="bottomLeft"
              >
                <img src={userDetail && userDetail.avatar ? userDetail.avatar : avatar} alt="" style={{
                  width: 40,
                  height: 40,
                  marginLeft: 20,
                  cursor: "pointer",
                  borderRadius: '50%',
                }}/>

              </Dropdown>
            </div>
          </div>
        </Header>
        <Content className="layout-content" style={{padding: 10}}>
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}
export default GeekLayout;
