import viVN from "antd/locale/vi_VN";

/**
 * 越南语
 */
const viLanguage = {
  ...viVN,
  "service.language.chinese": "Trung Quốc",
  "service.language.english": "Tiếng Anh",
  "service.language.french": "Tiếng Pháp",
  "service.language.japanese": "Tiếng Nhật",
  "service.language.korean": "Hàn Quốc",
  "service.language.vietnamese": "Tiếng Việt",
  "service.language.russian": "Tiếng Nga",
  "service.language.german": "Người Đức",
  "service.language.italian": "Tiếng Ý",
  "service.language.portuguese": "Ngôn ngữ Bồ Đào Nha",
  "service.language.thai": "Người Thái",
  "service.language.spanish": "Tiếng Tây Ban Nha",
  "service.language.indonesian": "Người In-đô-nê-xi-a",

  "service.my.order": "Đơn hàng của tôi",
  "service.my.account": "Tài khoản của tôi",
  "service.my.cost": "Tiêu thụ của tôi",
  "service.logout": "Đăng xuất",


  "service.menu.home": "Trang đầu",
  "service.menu.component": "Thành phần",
  "service.menu.workBench": "Bàn làm việc",
  "service.menu.manualRecord": "Tạo từ bản ghi âm",
  "service.menu.AIRecord": "Tự động tạo",
  "service.menu.organManage": "Quản lý tổ chức",
  "service.menu.component.PPT": "PPT",
  "service.menu.Translation": "Tạo bản dịch",


  "service.home.upload": "Tải lên nội dung",
  "service.home.add": "Thêm vào",
  "service.home.avatar": "Hình đại diện",
  "service.home.uploadPhoto": "Upload ảnh",
  "service.home.createAvatar": "Tạo Avatar",

  "service.nodata": "Không có dữ liệu",
  "service.unknown": "Không rõ",
  "service.unName": "Vô danh",


  "service.auto.all": "Tất cả",
  "service.auto.generated": "Tạo ra",
  "service.auto.editing": "Biên tập viên",

  "service.auto.list.owner": "Chủ sở hữu",
  "service.auto.list.updateTime": "Cập nhật",

  "service.auto.list.status.all": "Tất cả",
  "service.auto.list.status.generated": "Đã tạo",
  "service.auto.list.status.editing": "Trong biên tập",


  "service.insert.ppt": "Nhập khẩu PPT",
  "service.insert.title": "Mô tả",
  "service.insert.line1": "1. Về thời gian nhập: 10 trang, khoảng 30 phút. Khi bạn chọn một tệp, bạn sẽ được nhắc với thời gian nhập cụ thể",
  "service.insert.line2": "2. Trang chiếu sau khi nhập không hỗ trợ chỉnh sửa, chỉ hỗ trợ thêm người AI và chọn giọng nói AI",
  "service.insert.line3": "3. Sau khi nhập PPT, các slide và nhãn sẽ được trích xuất",
  "service.insert.line4": "4. Phông chữ PPT được khuyến nghị sử dụng Microsoft Yahei hoặc Songti",

  "service.record.openCamera": "Mở máy ảnh",
  "service.record.closeCamera": "Tắt máy ảnh",
  "service.record.openMicro": "Bật micro",
  "service.record.closeMicro": "Tắt micro",
  "service.record.enterFullScreen": "Truy cập toàn màn hình",
  "service.record.exitFullscreen": "Thoát khỏi chế độ toàn màn hình",
  "service.record.startRecord": "Mở ghi âm",
  "service.record.endRecord": "Kết thúc ghi âm",
  "service.record.rejectedVideo": "Bạn đã từ chối ủy quyền ghi âm và chúng tôi sẽ không quay video.",
  "service.record.rejectedMicro": "Bạn từ chối quyền sử dụng micrô, vui lòng kiểm tra thiết bị.",
  "service.record.noMicro": "Tôi không thể tìm thấy thiết bị micrô.",
  "service.record.microDisabled": "Micrô của bạn không khả dụng. Vui lòng kiểm tra thiết bị.",
  "service.record.wait20": "Vui lòng đợi 20 giây trước khi nó kết thúc.",
  "service.record.checkMicro": "Phát hiện thiết bị không thành công, vui lòng kiểm tra micrô.",
  "service.record.checkCarma": "Phát hiện thiết bị không thành công, vui lòng kiểm tra camera.",
  "service.record.rejectedCamera": "Bạn đã từ chối sự cho phép của máy ảnh, vui lòng kiểm tra thiết bị.",
  "service.record.noCamera": "Không tìm thấy thiết bị máy ảnh",
  "service.record.cameraDisabled": "Máy ảnh của bạn không có sẵn, vui lòng kiểm tra thiết bị.",

  "service.record.list.searchTips": "Vui lòng nhập tên tác phẩm",
  "service.record.list.owner": "Người sở hữu",
  "service.record.list.updateTime": "Cập nhật",
  "service.record.list.uploadTime": "Thời gian tải lên",
  "service.record.list.opr": "Hoạt động",
  "service.record.list.title": "Tiêu đề",
  "service.record.list.gender": "Giới tính",


  "service.avatar.createAvatar": "Tạo/chỉnh sửa Digital People",
  "service.avatar.gender": "Digital Man Giới tính",
  "service.avatar.female": "Phụ nữ",
  "service.avatar.male": "Nam giới",
  "service.avatar.uploadType": "Định dạng .jpg .png",
  "service.avatar.upload.rule-1": "Tải lên hình ảnh đầy đủ của khuôn mặt, hình ảnh càng chuẩn càng tốt",
  "service.avatar.upload.rule-2": "Cần đăng lên hình ảnh chính mặt, ngũ quan rõ ràng, bao gồm vùng vai, đề nghị kích thước không vượt quá 10 MB",
  "service.avatar.upload.rule-3": "Hình nền đề xuất màn màu xanh lá cây",


  "service.PPT.create": "Tạo/chỉnh sửa PPT",
  "service.PPT.description": "Mô tả",
  "service.PPT.inputDescription": "Vui lòng nhập mô tả",
  "service.PPT.rebuilding": "Tái tạo...",
  "service.PPT.generateAnOutline": "Tạo Phác thảo...",
  "service.PPT.rebuild": "Tái tạo",
  "service.PPT.next": "Tiếp theo",
  "service.PPT.back": "Trả lại",
  "service.PPT.outlineDescription": "Bạn có thể nhấp đúp để chỉnh sửa dàn ý hoặc tiếp tục như hiện tại",
  "service.PPT.chapter": "Chương",
  "service.PPT.language": "Ngôn ngữ",
  "service.PPT.pleaseSelectALanguage": "Vui lòng chọn ngôn ngữ",
  "service.PPT.size50": "Kích thước của PPT không được vượt quá 50 triệu.",
  "service.PPT.add": "Mới được thêm vào",
  "service.PPT.del": "Cắt ra",
  "service.PPT.demote": "Phá hủy",
  "service.PPT.selTheme": "Chọn một chủ đề",


  "service.record.list.digitalVoice": "Tạo/chỉnh sửa giọng nói AI",
  "service.record.list.name": "Tên",
  "service.record.list.introduction": "Giới thiệu",
  "service.record.list.voiceGender": "âm thanh Tình dục",
  "service.record.list.upload": "Tải lên",
  "service.record.list.clickUpload": "Bấm để tải lên",
  "service.record.list.agree": "Tôi đã đọc và đồng ý",
  "service.record.list.agreement": "Giao thức tạo và sử dụng giọng nói AI",
  "service.record.list.uploadType": "Định dạng .mp3 .wav",
  "service.record.list.inputName": "Vui lòng nhập tên",
  "service.record.list.inputIntroduction": "Vui lòng nhập giới thiệu",
  "service.record.list.selectGender": "Vui lòng chọn giới tính",
  "service.record.list.uploadFile": "Vui lòng tải tệp lên",
  "service.record.list.pleaseAgree": "Vui lòng đồng ý với thỏa thuận",
  "service.record.list.generate": "Tạo ra",
  "service.record.list.generateFailed": "Không tạo được",
  "service.record.list.gender.female": "Phụ nữ",
  "service.record.list.gender.male": "Nam giới",
  "service.record.list.selectVocal": "Chọn âm thanh số",
  "service.record.list.selectModel": "Vui lòng chọn PPT, giọng nói AI và người kỹ thuật số",
  "service.record.list.onPublish": "Đang xuất bản...",
  "service.record.list.publish": "Xuất bản",
  "service.record.list.requireVideoName": "Tên video là trường bắt buộc",
  "service.record.list.videoName": "Tên video",
  "service.record.list.insert": "Chèn vào",
  "service.record.list.ppt.uploadFailed": "PPT tải lên không thành công",
  "service.record.list.parsing": "Đang phân tích cú pháp...",
  "service.record.list.ppt.parseSuccess": "Phân tích cú pháp PPT thành công",
  "service.record.list.ppt.parseFailed": "Giải pháp PPT không thành công",
  "service.record.list.selectAva": "Chọn avatar",
  "service.record.cameraTip": "Bạn không bật camera hoặc micrô, bạn có tiếp tục không?",
  "service.record.videoName": "Tên video",


  "service.cost.amount": "Số tiền tiêu thụ",
  "service.cost.type": "Loại tiêu thụ",
  "service.cost.pieces": "Số lượng phần tiêu thụ",
  "service.cost.time": "Thời gian sử dụng",
  "service.cost.videoGenerate": "Tạo video",
  "service.cost.DH": "Sản xuất Digital Man",
  "service.cost.DV": "Sản xuất âm thanh kỹ thuật số",
  "service.cost.searchTips": "Vui lòng chọn nguồn tiêu thụ",
  "service.cost.video": "Video",
  "service.cost.vocal": "âm thanh",


  "service.order.selRechargeSource": "Vui lòng chọn nguồn nạp tiền",
  "service.order.selRechargeType": "Vui lòng chọn loại nạp tiền",
  "service.order.selPaymentState": "Vui lòng chọn trạng thái thanh toán",
  "service.order.inputID": "Vui lòng nhập số thứ tự",
  "service.order.recharge.wechat": "WeChat",
  "service.order.recharge.Alipay": "Alipay",
  "service.order.recharge.CloudQuickPass": "thanh toán đám mây flash",
  "service.order.payment.success": "Đã thanh toán",
  "service.order.payment.failed": "Không thanh toán",
  "service.order.invoice": "Lập hóa đơn",
  "service.order.id": "Số đơn đặt hàng",
  "service.order.rechargeSource": "Nguồn nạp tiền",
  "service.order.rechargeType": "Loại nạp tiền",
  "service.order.paymentState": "Trạng thái thanh toán",
  "service.order.num": "Số lượng đơn đặt hàng",
  "service.order.orderTime": "Đặt hàng thời gian",
  "service.order.payTime": "Thời gian thanh toán",
  "service.order.video": "Video",
  "service.order.digitalVoice": "Giọng nói AI",


  "service.translation.selectLan": "Vui lòng chọn ngôn ngữ",
  "service.translation.selectVoice": "Vui lòng chọn âm thanh",
  "service.translation.video": "Dịch video",
  "service.translation.originalLan": "Ngôn ngữ gốc",
  "service.translation.selOriginalLan": "Vui lòng chọn ngôn ngữ gốc",
  "service.translation.translLan": "Dịch ngôn ngữ",
  "service.translation.selTranslLan": "Vui lòng chọn ngôn ngữ dịch",
  "service.translation.uploadVideo": "Tải lên video",
  "service.translation.uploadVideoVail": "Vui lòng tải video lên",
  "service.translation.uploadSuccess": "Tải lên thành công",
  "service.translation.uploadFailed": "Tải lên không thành công",
  "service.translation.generating": "Đang tạo bản dịch",
  "service.translation.wait": "Vui lòng đợi...",


  "service.login.login": "Đăng nhập",
  "service.login.logout": "Thoát",
  "service.login.register": "Đăng ký",
  "service.login.registerTips": "Đăng ký tài khoản",


  "service.component.digitalVoice": "Giọng nói AI",
  "service.component.digitalFigures": "Người kỹ thuật số",
  "service.component.ppt": "Tạo PPT",
  "service.component.onGenerate": "Đang phát triển",
  "service.component.timeEstimate": "ngân sách thời gian",
  "service.component.timeEstimateDetail": "Dự kiến sẽ mất xxx phút và chi phí xxx meta.",
  "service.component.paySuccess": "Thanh toán thành công",

  "service.common.publishSuccess": "Xuất bản thành công",
  "service.common.deleteSuccess": "Xóa thành công",
  "service.common.deleteFail": "Xóa không thành công",
  "service.common.saveSuccess": "Lưu thành công",
  "service.common.saveFail": "Lưu không thành công",
  "service.common.success": "thành công",
  "service.common.Edit": "Chỉnh sửa",
  "service.common.confirm": "Xác nhận",
  "service.common.cancel": "Hủy bỏ",
  "service.common.setting": "Cài đặt",
  "service.common.none": "Tạm thời không có",
  "service.common.tip": "Gợi ý",
  "service.common.title": "Tiêu đề",
  "service.common.enterTitle": "Vui lòng nhập tiêu đề",
  "service.common.downloadFailed": "Tải xuống không thành công",


  "service.notice.errorRequest": "Lỗi yêu cầu:",
  "service.notice.timeout": "Yêu cầu hết thời gian, vui lòng kiểm tra mạng hoặc thử lại sau",
  "service.notice.request202": "Yêu cầu đã được chấp nhận và đi vào hàng đợi nền (nhiệm vụ không đồng bộ) nhưng không được xử lý hoàn thành",
  "service.notice.request203": "Yêu cầu thành công, thông tin không được ủy quyền, thông tin meta trả về không nằm trong máy chủ gốc, mà là một bản sao",
  "service.notice.request204": "Máy chủ xử lý thành công nhưng không trả về nội dung",
  "service.notice.request205": "Máy chủ đã xử lý thành công và máy khách (trình duyệt) nên đặt lại chế độ xem trang",
  "service.notice.request206": "Máy chủ đã xử lý thành công một số yêu cầu GET",
  "service.notice.request400": "Yêu cầu được thực hiện là sai (lỗi cú pháp yêu cầu khách hàng), máy chủ không thực hiện thao tác mới hoặc sửa đổi dữ liệu",
  "service.notice.request401": "Người dùng không có quyền (mã thông báo, tên người dùng, mật khẩu sai) hoặc đăng nhập đã hết hạn",
  "service.notice.request403": "Người dùng được ủy quyền nhưng quyền truy cập bị cấm",
  "service.notice.request404": "Tài nguyên được yêu cầu không thể tìm thấy (tài nguyên không tồn tại hoặc lỗi URL), máy chủ không hoạt động",
  "service.notice.request405": "Phương thức trong yêu cầu của khách hàng bị cấm bởi máy chủ hoặc phương thức yêu cầu lỗi",
  "service.notice.request406": "Định dạng yêu cầu của khách hàng sai hoặc nội dung trả về của máy chủ không thể được giải quyết bởi khách hàng",
  "service.notice.request410": "Tài nguyên được yêu cầu đã bị xóa vĩnh viễn và không bao giờ được trả lại",
  "service.notice.request412": "Lỗi điều kiện tiên quyết của khách hàng yêu cầu thông tin",
  'service.notice.request413': "Từ chối yêu cầu vì thực thể yêu cầu quá lớn và máy chủ không thể xử lý nó",
  'service.notice.request414': "URL yêu cầu quá dài (URL thường là địa chỉ web) và máy chủ không thể xử lý nó",
  "service.notice.request415": "Máy chủ không thể xử lý định dạng phương tiện đi kèm với yêu cầu",
  "service.notice.request416": "Phạm vi yêu cầu của khách hàng không hợp lệ",
  "service.notice.request422": "Khi một đối tượng được tạo, một lỗi xác thực xảy ra",
  "service.notice.request500": "Đã xảy ra lỗi trên máy chủ, vui lòng kiểm tra máy chủ",
  "service.notice.request501": "Máy chủ không hỗ trợ chức năng được yêu cầu và không thể hoàn thành yêu cầu",
  'service.notice.request502': "Lỗi cổng nhận được phản hồi không hợp lệ từ máy chủ từ xa",
  "service.notice.request503": "Dịch vụ không khả dụng, máy chủ tạm thời quá tải hoặc được duy trì",
  "service.notice.request504": "Gateway timeout, máy chủ hoạt động như một cổng hoặc proxy, không nhận được yêu cầu từ máy chủ từ xa một cách kịp thời",
  "service.notice.request505": "Máy chủ không hỗ trợ phiên bản giao thức HTTP được yêu cầu và không thể hoàn thành quá trình xử lý",
  "service.notice.request604": "Thời gian chờ đăng nhập, vui lòng đăng nhập lại",
  "service.notice.request603": "Thời gian chờ đăng nhập, vui lòng đăng nhập lại",


  "service.username": "Tên người dùng",
  "service.email": "hộp thư",
  "service.phone": "Số điện thoại di động",
  "service.organization": "Tổ chức",
  "service.OK": "Được rồi",


  "tips.login.notSupport": "Tạm thời không hỗ trợ",
  "tips.login.success": "Đăng nhập thành công",
  "tips.login.fail": "Đăng nhập thất bại",
  "tips.login.acc": "Số tài khoản",
  "tips.login.pass": "Mật khẩu",
  "tips.login.agreeTips": "Bạn có đồng ý với thỏa thuận bảo mật người dùng hay không",
  "tips.login.account": "Vui lòng nhập tài khoản",
  "tips.login.password": "Vui lòng nhập mật khẩu",
  "tips.login.loginWithVerify": "Đăng nhập bằng CAPTCHA",
  "tips.login.check": "Xem",
  "tips.privacy.title": "Thỏa thuận bảo mật người dùng",
  "tips.update.success": "Sửa đổi thành công",
  "tips.update.error": "Sửa đổi thất bại",
  "tips.logOff.success": "Bạn đã đăng xuất",
  "tips.logOff.error": "Đăng xuất thất bại",
  "tips.logOff.warning": "Bạn có chắc chắn muốn đăng xuất?",
  "tips.email.format": "Vui lòng nhập đúng hộp thư",
  "tips.file.size5": "Kích thước tập tin không thể vượt quá 5M",
  "tips.logOff.tips": "Không thể phục hồi sau khi đăng xuất, hãy thận trọng",
  "tips.psw.length": "Mật khẩu phải dài từ 6-20 chữ số",
  "tips.psw.chars": "Mật khẩu phải chứa hai chữ cái hoa chữ thường, số, ký tự đặc biệt",
  "tips.psw.rule": "Vui lòng nhập mật khẩu mới, mật khẩu dài 6-20 chữ số, phải chứa hai chữ cái hoa chữ thường, số, ký tự đặc biệt",
  "tips.download.warning": "Đang tải xuống, đừng nhấp lại!",
  "tips.login.theAccountNumberOrPasswordIsIncorrect": "Lỗi tài khoản hoặc mật khẩu!",
  "tips.login.getAVericationCode": "Nhận mã xác minh",
  "tips.login.RetryinSeconds": "thử lại sau giây",


  "btn.text.release": "Xuất bản",
  "btn.text.modify": "Sửa đổi",

  "service.user.center": "Trung tâm người dùng",
  "service.user.updateAvatar": "Sửa đổi hình đại diện",
  "service.user.avatarFormat": "Hình ảnh phải lớn hơn 500*500 pixel, hỗ trợ các định dạng JPG, JPEG, PNG và SVG và kích thước không được vượt quá 5M",
  "service.user.nickname": "biệt danh",
  "service.user.cancelAccount": "Hủy tài khoản",
  "service.user.cancelAccountWarning": "Không thể khôi phục nó sau khi đăng xuất, hãy cẩn thận",
  "service.user.logOff": "Đăng xuất",
  "service.user.changePsw": "Thay đổi mật khẩu",

}

export default viLanguage;
